<template>
  <div class="mb-2">
    <Modal
      id="signOutFailed"
      header="Log Out Failed"
      text="We've encountered an error while trying to log you out."
      fade
    />
    <ConfirmDialog
      :show="showConfirmDialog"
      :closeFunction="signOut"
      @dialog-close="resetDialog"
    />
    <section class="container-sm px-1 md:pt-2-half">
      <h1 class="my-1">Account</h1>
      <a v-if="!email" href="" class="button primary" @click.prevent="signIn">
        {{ $t('SignIn') }}
      </a>
      <div v-else>
        <h2 class="account__name bg-tertiary text-white heading-3">
          {{ $t('YouAreLoggedIn') }}
        </h2>
      </div>
      <div class="flex flex-col mt-1-half">
        <a
          :href="config.links.FAQ"
          target="_blank"
          class="link py-1 hover:bg-gray200 transition-colors duration-700"
          >{{ $t('FrequentlyAskedQuestionsLink') }}</a
        >
        <a
          :href="config.links.privacyPolicy"
          target="_blank"
          class="link py-1 hover:bg-gray200 transition-colors duration-700"
          >{{ $t('PolicyLink') }}</a
        >
        <a
          v-if="email"
          href="#"
          class="
            link
            text-red
            py-1
            hover:bg-gray200
            transition-colors
            duration-700
          "
          @click.prevent="showDialog"
        >
          {{ $t('SignOut') }}
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

import Modal from '@/components/Modal.vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'Account',
  components: {
    ConfirmDialog,
    Modal,
  },
  data() {
    return {
      email: '',
      showConfirmDialog: false,
    };
  },
  computed: mapState(['config']),
  async mounted() {
    try {
      const user = await this.$auth.currentAuthenticatedUser();
      this.email = user.attributes.email;
    } catch (e) {
      this.email = '';
    }
  },
  methods: {
    signIn() {
      this.$auth.federatedSignIn();
    },
    async signOut() {
      try {
        await this.$auth.signOut({ global: true });
        this.email = '';
      } catch (e) {
        this.$store.dispatch('setActiveModal', 'signOutFailed');
      }
    },
    showDialog() {
      this.showConfirmDialog = true;
    },
    resetDialog() {
      this.showConfirmDialog = false;
    },
  },
});
</script>
